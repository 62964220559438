import React, { useRef, useEffect, useState } from "react"
import Slider from "react-slick"
import SliderArrow from "../../../components/sliders/slider_arrow"
import { SliderSlickButton } from "../../../components/buttons/_button_slider_slick"
import DetailLogo from "../detail_logo"
import { Picture } from "react-responsive-picture"
import { useCurrenDeviceMaintenance } from "../../../hooks/useCurrentDeviceMaintenance"

import Imgarbol from "../../../../static/assets/images/about_us/arbol.png"
import Imgmundo from "../../../../static/assets/images/about_us/mundo.png"
import Imgconchiglia from "../../../../static/assets/images/about_us/conchiglia.png"
import Imgperla from "../../../../static/assets/images/about_us/perla.png"
import Imgflor from "../../../../static/assets/images/about_us/flor.png"

const SectionServices = () => {
  const OurValues = [
    {
      id: 1,
      image: Imgarbol,
      title: "Proceder desde la integridad",
      description:
        " Actuar con respeto y honestidad no es una elección. Es un deber.",
    },
    {
      id: 2,
      image: Imgmundo,
      title: "Las personas son primero",
      description:
        "La fuerza radica en la diversidad. Dale una oportunidad a la gente y alienta su camino para que tengan éxito.",
    },
    {
      id: 3,
      image: Imgconchiglia,
      title: "Inspira a la excelencia",
      description:
        "Crea oportunidades y comprometerse a hacerlas realidad. Explora, aprende, mejora.",
    },
    {
      id: 4,
      image: Imgperla,
      title: "Empezar por los clientes",
      description:
        "Escuchar, estar atentos y prestar atención a los detalles, para entregar soluciones que superen las expectativas.",
    },
    {
      id: 5,
      image: Imgflor,
      title: "Creer en la sustentabilidad",
      description:
        "Pensar en el futuro. La eficiencia energética y el confort son posibles y necesarios. ¡Vale la pena!",
    },
  ]

  const sliderOurValues = useRef(null)
  const [winWidth, setWinWidth] = useState(null)
  const [device] = useCurrenDeviceMaintenance()

  let sliderArrow = new SliderArrow()
  const bannerSectionRef = useRef(null)
 
  useEffect(() => {
    if (!bannerSectionRef.current) return
    sliderArrow.init(bannerSectionRef)
  }, [sliderArrow])

  const settings = {
    customPaging: function(i) {
      return <span className="pagination__bullet"></span>
    },
    dots: true,
    dotsClass: "pagination__dots",
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: winWidth,
    slidesToScroll: 1,
    prevArrow: <SliderSlickButton _className="slick-button-prev" />,
    nextArrow: (
      <SliderSlickButton _className="slick-button-next" isNext={true} />
    ),
  }
  useEffect(() => {
    console.log('device-->', device)

    if(device === "mobile"){
      setWinWidth(1)
    }
    if(device === "tablet"){
      setWinWidth(2)
    }
    if(device === "desktopMin"){
      setWinWidth(3)
    }
    if(device === "desktop" || device === "xlg"){
      setWinWidth(5)
    }

  }, [device])

  return (
    <div className="container_our_values">
      <DetailLogo _name={""} _title="Nuestros Valores" />
      <div className="container_card">
        <div
          className="model_detail_section detail_youtube "
          ref={bannerSectionRef}
        >
          <div className="our_values_stage">
            <div className="detail_youtube_slider our_values_details">
              <div
                className={`banner__prev cursor__change`}
                onClick={() => sliderOurValues.current.slickPrev()}
              ></div>
              <div
                className={`banner__next cursor__change`}
                onClick={() => sliderOurValues.current.slickNext()}
              ></div>
              <Slider ref={sliderOurValues} {...settings}>
                {OurValues.map((item, index) => {
                  return (
                    <div key={index} className="container_card_item">
                      <div className="container_image">
                        <Picture
                          sources={[
                            {
                              srcSet: item.image,
                              media: "(max-width: 767px)",
                            },
                            {
                              srcSet: item.image,
                              media: "(max-width: 1366px)",
                            },
                            {
                              srcSet: item.image,
                              media: "(min-width: 1367px)",
                            },
                          ]}
                        />
                      </div>
                      <div className="container_card_info">
                        <div className="container_title">
                          <p className="text_title">{item.title}</p>
                        </div>
                        <div className="container_description">
                          <p className="text_description">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SectionServices
