import React from "react"
import DetailLogo from "../detail_logo"
import { Picture } from "react-responsive-picture"

import aristonMobile from "../../../../static/assets/images/about_us/ariston_320.png"
import aristonTablet from "../../../../static/assets/images/about_us/ariston_768.png"
import aristonMd from "../../../../static/assets/images/about_us/ariston_1024.png"
import aristonDesktop from "../../../../static/assets/images/about_us/ariston_1920.png"

const StorytellingGroup = () => {
  return (
    <div className="container_section_group">
      <DetailLogo _name={"El Grupo Líder"} _title=""></DetailLogo>
      <div className="container__text__group">
        <p>
          Hoy, Calorex forma parte de Ariston Group, que con 90 años en
          el mercado, es uno de los líderes mundiales en la creación,
          fabricación y comercialización de creaciones innovadoras en sistemas
          de calefacción y agua caliente para espacios domésticos, comerciales e
          industriales.
        </p>
      </div>
      <div className="container__description__group">
        <p>
          Brindando confort a todos los usuarios con productos de la más alta
          calidad, durabilidad y eficiencia. <br />
          <strong className="sustainable_comfort">
            sustainable comfort for everyone since 1930.
          </strong>
        </p>
      </div>
      <div className="container_img_Ariston">
        <a href="https://www.aristonthermo.com/" target="_blank" rel="noopener noreferrer">
          <Picture
            className="ariston_image"
            sources={[
              {
                srcSet: aristonMobile,
                media: "(max-width: 767px)",
              },
              {
                srcSet: aristonTablet,
                media: "(max-width: 1023px)",
              },
              {
                srcSet: aristonMd,
                media: "(max-width: 1359px)",
              },
              {
                srcSet: aristonDesktop,
                media: "(min-width: 1360px)",
              },
            ]}
          />
        </a>
      </div>
    </div>
  )
}

export default StorytellingGroup
