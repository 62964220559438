import React from "react"
import { Picture } from "react-responsive-picture"

import imageMobile from "../../../../static/assets/images/about_us/home_mobile.png"
import imageTablet from "../../../../static/assets/images/about_us/home_tablet.png"
import imageHome from "../../../../static/assets/images/about_us/home_desktop.png"

const StorytellingQuality = () => {
  return (
    <div>
      <div className="so-storytelling_quality">
        <div className="so-storytelling_quality__text">
          <p>
            <strong>
              Con esto, Calorex logra posicionarse como una marca de desempeño
              global que establece un índice de calidad de primer mundo.
            </strong>
          </p>
        </div>
      </div>
      <div className="container_img_home">
        <Picture
          className="imagen_home"
          sources={[
            {
              srcSet: imageMobile,
              media: "(max-width: 767px)",
            },
            {
              srcSet: imageTablet,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: imageHome,
              media: "(max-width: 1366px)",
            },
            {
              srcSet: imageHome,
              media: "(min-width: 1367px)",
            },
          ]}
        />
      </div>
    </div>
  )
}

export default StorytellingQuality
