import React from "react"
import DetailLogo from "../detail_logo"
import { Picture } from "react-responsive-picture"

import GreatPlaceDesktop from "../../../../static/assets/images/about_us/great_desktop.jpg"

const greatPlaceWork = () => {
  return (
    <div>
      <div className="container_great_place">
        <div className="container_info">
          <div className="container_logo">
            <DetailLogo
              _name={""}
              _title=""
              _className="logo_section_pillars"
            ></DetailLogo>
          </div>
          <div className="container_title">
            <p>Great Place To Work</p>
          </div>
          <div className="container_description">
            <p>
              El compromiso y vocación de cada miembro del equipo Calorex, se
              refleja al ser reconocidos con el galardón GREAT PLACE TO WORK,
              como una de las mejores 100 empresas para trabajar en México.
            </p>
            <br />
            <p>*Reforma, 3 de diciembre del 2018.</p>
          </div>
        </div>

        <div className="container_image_great">
          <Picture
            sources={[
              {
                srcSet: GreatPlaceDesktop,
                media: "(max-width: 768px)",
              },
              {
                srcSet: GreatPlaceDesktop,
                media: "(min-width: 769px)",
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default greatPlaceWork
