import React from 'react'
import { gsap, TimelineMax } from 'gsap'
import CssClassManager from '../../components/_helpers/_css_class_manager'
class  SliderManager extends React.Component {
  
    props = null;
    mask = null;
    slider = null;
    //how manny items need to move every time    
    itemsToSlide = 1;
    //how manny items need to show in the mask, overwriten in js
    itemsToShow = 1;
    sliderWidth = 0 ;
    
    is_mouse_down = false;
    x_start =0;
    x_overhead =0;
    //how manny pixels need to move every time
    width_per_scroll =0;
    //how manny pixels is the width of every item
    width_per_item =0;
    current_item = 0
    item_count =0;
    x_dragged =0;
    //how manny pixels needs scroll to move to the next/prev item otherwise return to the original x
    x_limit_dragged = 25;
    x_added = 0;
    //justa a flag to prevents animation's overlap
    is_busy = false;
    window_width = 0;
    mask_width =0;
    is_touch_controled = false;
    is_click_controled = false;

    nav_pages =0;
    nav_container = null;
    cssClassManager =new CssClassManager();

    constructor(_props){
        super(_props);
        this.props = _props;
        
        gsap.registerPlugin(TimelineMax);
        this.onResize();
        //if this becomes globals, need refactor to set a main container
        if (typeof document !== 'undefined') {
            this.mask= document.getElementsByClassName('o-slider_manager_body');
            this.slider= document.getElementsByClassName('o-slider_manager_container');
            this.nav_container = document.getElementsByClassName('o-slider_manager_nav'); 
        }
        
    
    }
    componentDidMount(){
        if(this.window_width<768)
        {
            this.itemsToShow = 1;
            this.setListennersMobile();
        }
        else{
            if(this.window_width>=768 && this.window_width<1024 )
            {
                this.itemsToShow = 2;
                this.setListennersMobile();
            }
            else{
                
                this.itemsToShow = 3;
                this.setListennersDesktop();
            }
        }
        this.item_count = this.props.children.length;
        this.nav_pages =  this.item_count - this.itemsToShow +1;      
        this.setSizes();
           
    }
    setSizes(){
        this.width_per_item = (this.mask[0].offsetWidth/this.itemsToShow);
                
        this.sliderWidth= this.width_per_item * this.item_count;        
        this.slider[0].style.width = this.sliderWidth+'px';        
        
        this.width_per_scroll = this.width_per_item *this.itemsToSlide;
        this.mask_width= this.width_per_item *this.itemsToShow;
        
    }
    setListennersMobile(){
        this.mouseDown= this.mouseDown.bind(this);
        this.mask[0].addEventListener('mousedown',this.mouseDown);
        this.mask[0].addEventListener('touchstart',this.mouseDown);
    }
    setListennersDesktop(){
        this.clickHandler= this.clickHandler.bind(this);
        this.mask[0].addEventListener('click',this.clickHandler);
        
    }
    clickHandler(_event){
        if(this.is_busy) return;
        let width = window.innerWidth / 2;
            if(_event.clientX > width) {            
                this.goNext()      
            } else if(_event.clientX <= width) {            
                this.goPrev()
            }
    }
    onResize(){
        if (typeof window !== 'undefined') {
            this.window_width= window.innerWidth;
        }
    }
    
    mouseDown(event){
        const {
         // target, 
            clientX, 
        //  clientY
        } = event;
        if(this.is_mouse_down || this.is_busy) return;
        
        if(event.touches)
        {
            this.x_start =event.touches[0].clientX;
        }
        else{            
            this.x_start =clientX;
        }
        
        this.is_mouse_down = true;

        this.mouseMove= this.mouseMove.bind(this);
        this.mouseUp= this.mouseUp.bind(this);
        
        this.mask[0].addEventListener('mousemove',this.mouseMove);
        this.mask[0].addEventListener('touchmove',this.mouseMove);

        this.mask[0].addEventListener('mouseup',this.mouseUp);
        this.mask[0].addEventListener('touchend',this.mouseUp);

        
    }
    mouseUp(event){
        
        if(!this.is_mouse_down || this.is_busy) return;
        this.x_start =0;
        this.is_mouse_down = false;

        this.mask[0].removeEventListener('mousemove',this.mouseMove);
        this.mask[0].removeEventListener('touchmove',this.mouseMove);

        this.mask[0].removeEventListener('mouseup',this.mouseUp);
        this.mask[0].removeEventListener('touchend',this.mouseUp);
        if(this.x_overhead>0 && this.x_overhead>this.x_limit_dragged)
        {
            this.goPrev()
        }
        else{
            if(this.x_overhead<0 && this.x_overhead<this.x_limit_dragged*-1)
            {
                this.goNext()    
            }
            else{
                this.backToStart();
            }
        }
        
    }
    mouseMove(event){
        const {
        //  target, 
            clientX, 
         // clientY,
            touches} = event;
        if(this.is_busy) return;
        
        if(this.is_mouse_down )
        {            
            if(touches){
                this.x_overhead = touches[0].clientX - this.x_start ;
            }
            else{
                this.x_overhead = clientX - this.x_start ;
            }            
            this.slider[0].style.transform='translateX('+(this.x_overhead+this.x_added)+'px)';
        }
    }
    mouseLeave(event){
        
        this.is_mouse_down = false;
        if(this.x_overhead>this.x_limit_dragged)
        {
            this.goPrev()
        }
        else{
            if(this.x_overhead<this.x_limit_dragged*-1)
            {
                
                this.goNext()
            }
        }

    }

    goNext(){
        
        this.current_item= this.current_item< this.item_count-this.itemsToShow ? this.current_item+1 : this.item_count-this.itemsToShow;
        
        this.animateSlide();
    }
    goPrev(){
        
        this.current_item= this.current_item > 0 ? this.current_item-1 : 0;
        
        this.animateSlide(); 
    }
    backToStart(){
        
        this.animateSlide(0.25);
    }
    animateSlide(time=0.5){
        
        let x_end = (this.current_item*(this.width_per_scroll)*-1);
        let max_scroll = (this.sliderWidth-(this.mask_width))*-1
        if(x_end<=max_scroll)
        {
            x_end= max_scroll;
            
        }
        else{
            if(x_end>=0)
            {
                x_end= 0;
                
            }   
        }
        let myTween = new TimelineMax({paused: true,onStart: ()=>{ this.is_busy=true;},onComplete: ()=>{
            this.is_busy=false;
            this.x_added=x_end;
            this.updateNav()
        }});
        myTween
            .fromTo(this.slider,0.5,{x: ((this.x_overhead+this.x_added)+'px')},{x: x_end+'px'})
            .play(); 
    }
    createNav()
    {
        let nav_items =[];
        
        for(let i=0;i<this.nav_pages;i++){
            let selected_class= i===0 ? 'slick-active' : '';
            nav_items.push(
                <li className={`${selected_class} `} key={`o-slider_nav_item${i}`} data-page={i} onClick={()=>{this.navClick(i)}}>
                    <span className="pagination__bullet"></span>
                </li>
            )
        }

        return nav_items;
    }
    updateNav(){
        let current_active = this.nav_container[0].getElementsByClassName('slick-active')[0];
        this.cssClassManager.remove_class(current_active,'slick-active');
        let new_active = this.nav_container[0].querySelector("ul li:nth-child("+(this.current_item+1)+")");        
        this.cssClassManager.add_class(new_active,'slick-active');
        
    }
    navClick(_index){
        
        if(this.is_busy) return false;
        this.current_item=_index;
        this.animateSlide()
    }
    render() {
        
        return (
            <div className="o-slider_manager" >
                <div className="o-slider_manager_nav">
                    <ul className="pagination__dots">
                        {this.createNav()}
                    </ul>
                </div>
                <div className="o-slider_manager_body">
                    <div className="o-slider_manager_container">
                        {this.props.children.map((_item,_key)=>{
                            return (
                                <div key={`o-slider_manager-item_${_key}`} className="o-slider_manager_item" style={{width: this.width_per_item}}>
                                    {_item}
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default SliderManager;