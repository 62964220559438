import React from "react"
import Layout from "../components/layout"

import { AboutUsStorytelling } from "../content/nosotros/storytelling/seccion-nosotros-storytelling"
import StorytellingQuality from "../content/nosotros/storytelling/storytelling_quality"
import OurValues from "../content/nosotros/_sections/section-nuestros_valores"
import QualityProcess from "../content/nosotros/_sections/section_quality_process"
import AboutItem from "../components/_aboutUs/_aboutItem"
import OurPillarsText from "../content/nosotros/_sections/_section_storytelling_group"
import ModelAdvantages from "../content/model_detail/_sections/_model_advantages"
import GreatPlaceWork from "../content/nosotros/_sections/_section_great_place_work"
import "../styles/pages/nosotros/_nosotros.scss"

import imgFamily from "../../static/assets/images/about_us/familia.jpg"
import imgmundo from "../../static/assets/images/about_us/img_mundo.jpg"
import imgDurabilidad from "../../static/assets/images/about_us/durabilidad.jpg"
import iconSeguridad from "../components/svg/section_aboutUs/icon_seguridad.svg"
import iconEco from "../components/svg/section_aboutUs/icon_eco.svg"
import iconDurabilidad from "../components/svg/section_aboutUs/icon_durabilidad.svg"

const AboutUS = () => {
  const OurPillar = [
    {
      id: 1,
      image: imgFamily,
      title: "Seguridad",
      description:
        "Trabajamos con componentes de máxima seguridad y altos estándares de calidad, para garantizar la tranquilidad de nuestros usuarios.",
      icon: iconSeguridad,
    },
    {
      id: 2,
      image: imgmundo,
      title: "Eco-Friendly",
      description:
        "Responsabilidad social al utilizar tecnologías de alta eficiencia energética y materiales amigables con el medio ambiente.",
      icon: iconEco,
    },
    {
      id: 3,
      image: imgDurabilidad,
      title: "Durabilidad",
      description:
        "Productos Hechos en México, con materiales de la más alta calidad y refacciones con distribución a nivel nacional. Ofrecemos la mayor garantía extendida del mercado.",
      icon: iconDurabilidad,
    },
  ]
  return (
    <Layout>
      <div className="nosotros-bg-gradient"></div>
      <AboutUsStorytelling />
      <StorytellingQuality />
      <OurValues />
      <QualityProcess />
      <AboutItem />
      <ModelAdvantages _pillars={OurPillar}></ModelAdvantages>
      <OurPillarsText />
      <GreatPlaceWork />
    </Layout>
  )
}

export default AboutUS
