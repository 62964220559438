import React from "react"
import DetailLogo from "../detail_logo"

const sectionServices = () => {
  return (
    <div className="container_quality_process">
      <DetailLogo _name={""} _title="Nuestros procesos de calidad" />
      <div className="container_text">
        <p className="text_description_quality">
          En Calorex, seguimos rigurosos procesos de fabricación y control de
          calidad para crear tecnologías especializadas en calentamiento de
          agua.
        </p>
      </div>
    </div>
  )
}
export default sectionServices
