import React from "react"
import DetailLogo from "../detail_logo"
import { Picture } from "react-responsive-picture"

import imageHeaderMb from "../../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_mb.png"
import imageHeaderTb from "../../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_tb.png"
import imageHeaderLg from "../../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_lp.png"


export const AboutUsStorytelling = props => {
  return (
    <div className="storytelling__container__abautUs">
      <div className="container_img_header">
        <Picture
          className="imagen_header"
          sources={[
            {
              srcSet: imageHeaderMb,
              media: "(max-width: 767px)",
            },
            {
              srcSet: imageHeaderTb,
              media: "(max-width: 1023px)",
            },
            {
              srcSet: imageHeaderLg,
              media: "(min-width: 1024px)",
            },
          ]}
        />
      </div>
      <DetailLogo _name={"Familia Calorex"} _title=""></DetailLogo>
      <div className="storytelling__text__abautUs">
        <p>
          Con más de 75 años en el mercado, Calorex se ha posicionado como el
          líder en ofrecer soluciones en calentamiento de agua en México.
          Calorex se encuentra en constante innovación, para ofrecer los mejores
          calentadores de agua.
        </p>
      </div>
      <div className="storytelling__description__abautUs">
        <p>
          Desarrollando tecnologías cada vez más eco-eficientes y respetuosas
          con el medio ambiente. Sus estándares de calidad global, durabilidad,
          sustentabilidad y ahorro de energía, son características que nos
          llevan a ser reconocidos mundialmente.
        </p>
      </div>
    </div>
  )
}
