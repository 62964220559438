import React from "react"
// import { TextGradient } from "../_text/_text_gradient"
import { Picture } from "react-responsive-picture"
import "../../styles/_components/_about_us_item/about_us_item.scss"

import Imgdevelop from "../../../static/assets/images/about_us/desarrollo.jpg"
import Imgfabrication from "../../../static/assets/images/about_us/fabricacion.png"

const AboutUsItem = () => {
  return (
    <div>
      <div className="container_aboutUs_item">
        {/* <div className="calorex_family_mobile">
          <p>Familia Calorex</p>
        </div> */}
        <div className="container_left">
          <Picture src={Imgdevelop} alt="desarrollo" />
        </div>
        <div className="container_right">
          {/* <div className="calorex_family">
            <p>Familia Calorex</p>
          </div> */}
          <div className="container_title">
            <p>Desarrollo de nuevos productos</p>
          </div>
          <div className="container_description">
            <p>
              Cuidamos que nuestros productos estén a la vanguardia, en
              constante innovación tecnológica y diseño, creando calentadores
              más eficientes y amigables con el medio ambiente.
            </p>
          </div>
        </div>
      </div>

      <div className="container_aboutUs_item">
        {/* <div className="calorex_family_mobile">
          <p>Familia Calorex</p>
        </div> */}
        <div className="container_image_mobile">
          <div className="container_left">
            <Picture src={Imgfabrication} alt="fabricacion" />
          </div>
        </div>
        <div className="container_right">
          {/* <div className="calorex_family">
            <p>Familia Calorex</p>
          </div> */}
          <div className="container_title">
            <p>Fabricación y control de calidad</p>
          </div>
          <div className="container_description">
            <p>
              Cada paso en nuestro proceso de producción es minuciosamente
              supervisado con los más rigurosos estándares de calidad y
              seguridad internacionales, brindando siempre productos de
              excelencia a nuestros usuarios.
            </p>
          </div>
        </div>
        <div className="container_left container_image_desktop ">
          <Picture src={Imgfabrication} alt="fabricacion" />
        </div>
      </div>
    </div>
  )
}

export default AboutUsItem
